/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.144.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { PageProtokollRest } from '../models';
/**
 * ProtokollResourceApi - axios parameter creator
 * @export
 */
export const ProtokollResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle verfahrens unspezifischen Protokolle ausgeliefert.<br> Die gelieferten Protokolle sind Protokolleinträge aus dem Admin Bereich wie z.B.<br> Änderungen an Systemparametern, Verfahrenskonfigurationen, Codelisten.<br> Die Ergebnismenge kann mittels optionaler Suchparameter eingeschränkt werden.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Ausliefern der verfahrens unspezifischen Protokolle.
         * @param {Array<GetProtokolleStatus>} [status] Status
         * @param {Array<GetProtokolleTyp>} [typ] Protokolltypen
         * @param {string} [beschreibung] Beschreibung
         * @param {string} [erfasser] Erfasser
         * @param {string} [erfassungsdatum] Erfassungsdatum
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProtokolle: async (status?: Array<GetProtokolleStatus>, typ?: Array<GetProtokolleTyp>, beschreibung?: string, erfasser?: string, erfassungsdatum?: string, page?: number, size?: number, sort?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protokolle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (typ) {
                localVarQueryParameter['typ'] = typ;
            }

            if (beschreibung !== undefined) {
                localVarQueryParameter['beschreibung'] = beschreibung;
            }

            if (erfasser !== undefined) {
                localVarQueryParameter['erfasser'] = erfasser;
            }

            if (erfassungsdatum !== undefined) {
                localVarQueryParameter['erfassungsdatum'] = (erfassungsdatum as any instanceof Date) ?
                    (erfassungsdatum as any).toISOString() :
                    erfassungsdatum;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProtokollResourceApi - functional programming interface
 * @export
 */
export const ProtokollResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProtokollResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle verfahrens unspezifischen Protokolle ausgeliefert.<br> Die gelieferten Protokolle sind Protokolleinträge aus dem Admin Bereich wie z.B.<br> Änderungen an Systemparametern, Verfahrenskonfigurationen, Codelisten.<br> Die Ergebnismenge kann mittels optionaler Suchparameter eingeschränkt werden.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Ausliefern der verfahrens unspezifischen Protokolle.
         * @param {Array<GetProtokolleStatus>} [status] Status
         * @param {Array<GetProtokolleTyp>} [typ] Protokolltypen
         * @param {string} [beschreibung] Beschreibung
         * @param {string} [erfasser] Erfasser
         * @param {string} [erfassungsdatum] Erfassungsdatum
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProtokolle(status?: Array<GetProtokolleStatus>, typ?: Array<GetProtokolleTyp>, beschreibung?: string, erfasser?: string, erfassungsdatum?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageProtokollRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProtokolle(status, typ, beschreibung, erfasser, erfassungsdatum, page, size, sort, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProtokollResourceApi.getProtokolle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProtokollResourceApi - factory interface
 * @export
 */
export const ProtokollResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProtokollResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle verfahrens unspezifischen Protokolle ausgeliefert.<br> Die gelieferten Protokolle sind Protokolleinträge aus dem Admin Bereich wie z.B.<br> Änderungen an Systemparametern, Verfahrenskonfigurationen, Codelisten.<br> Die Ergebnismenge kann mittels optionaler Suchparameter eingeschränkt werden.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
         * @summary Endpunkt zum Ausliefern der verfahrens unspezifischen Protokolle.
         * @param {Array<GetProtokolleStatus>} [status] Status
         * @param {Array<GetProtokolleTyp>} [typ] Protokolltypen
         * @param {string} [beschreibung] Beschreibung
         * @param {string} [erfasser] Erfasser
         * @param {string} [erfassungsdatum] Erfassungsdatum
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProtokolle(status?: Array<GetProtokolleStatus>, typ?: Array<GetProtokolleTyp>, beschreibung?: string, erfasser?: string, erfassungsdatum?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<PageProtokollRest> {
            return localVarFp.getProtokolle(status, typ, beschreibung, erfasser, erfassungsdatum, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProtokollResourceApi - object-oriented interface
 * @export
 * @class ProtokollResourceApi
 * @extends {BaseAPI}
 */
export class ProtokollResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b><p>Mit diesem Endpunkt werden alle verfahrens unspezifischen Protokolle ausgeliefert.<br> Die gelieferten Protokolle sind Protokolleinträge aus dem Admin Bereich wie z.B.<br> Änderungen an Systemparametern, Verfahrenskonfigurationen, Codelisten.<br> Die Ergebnismenge kann mittels optionaler Suchparameter eingeschränkt werden.</p><p><b>Benötigte Rechte:</b> ADMIN</p>
     * @summary Endpunkt zum Ausliefern der verfahrens unspezifischen Protokolle.
     * @param {Array<GetProtokolleStatus>} [status] Status
     * @param {Array<GetProtokolleTyp>} [typ] Protokolltypen
     * @param {string} [beschreibung] Beschreibung
     * @param {string} [erfasser] Erfasser
     * @param {string} [erfassungsdatum] Erfassungsdatum
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtokollResourceApi
     */
    public getProtokolle(status?: Array<GetProtokolleStatus>, typ?: Array<GetProtokolleTyp>, beschreibung?: string, erfasser?: string, erfassungsdatum?: string, page?: number, size?: number, sort?: Array<string>, options?: RawAxiosRequestConfig) {
        return ProtokollResourceApiFp(this.configuration).getProtokolle(status, typ, beschreibung, erfasser, erfassungsdatum, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetProtokolleStatus = {
    INFO: 'INFO',
    FEHLER: 'FEHLER'
} as const;
export type GetProtokolleStatus = typeof GetProtokolleStatus[keyof typeof GetProtokolleStatus];
/**
 * @export
 */
export const GetProtokolleTyp = {
    BENACHRICHTIGUNG_FESTSTELLUNG: 'BENACHRICHTIGUNG_FESTSTELLUNG',
    BENACHRICHTIGUNG_VERFAHRENSSTANDWECHSEL: 'BENACHRICHTIGUNG_VERFAHRENSSTANDWECHSEL',
    BENACHRICHTIGUNG_VEROEFFENTLICHUNG: 'BENACHRICHTIGUNG_VEROEFFENTLICHUNG',
    BETEILIGUNG: 'BETEILIGUNG',
    BLACKLIST: 'BLACKLIST',
    CLAMAV: 'CLAMAV',
    CODELISTE: 'CODELISTE',
    CSW_T: 'CSW_T',
    DCAT_AP_PLU: 'DCAT_AP_PLU',
    HOUSEKEEPING_DOKUMENTE: 'HOUSEKEEPING_DOKUMENTE',
    HOUSEKEEPING_TRANSFERMESSAGE: 'HOUSEKEEPING_TRANSFERMESSAGE',
    TEMPLATE_LOAD: 'TEMPLATE_LOAD',
    KONTAKTE: 'KONTAKTE',
    LIQUIBASE: 'LIQUIBASE',
    MASSEN_UPDATE: 'MASSEN_UPDATE',
    MQ: 'MQ',
    OKTAGON: 'OKTAGON',
    PLANNAMENVALIDIERUNGSREGELN: 'PLANNAMENVALIDIERUNGSREGELN',
    SYSTEM_PARAMETER: 'SYSTEM_PARAMETER',
    VERFAHREN_ALLGEMEIN: 'VERFAHREN_ALLGEMEIN',
    VERFAHREN_KONFIGURATION: 'VERFAHREN_KONFIGURATION',
    VERFAHREN_LOESCHEN: 'VERFAHREN_LOESCHEN',
    VERFAHREN_WIEDERHERSTELLEN: 'VERFAHREN_WIEDERHERSTELLEN',
    VFDBIMPORT: 'VFDBIMPORT',
    VORLAGEN_VALIDIERUNG: 'VORLAGEN_VALIDIERUNG',
    XPLANVERFAHREN_IMPORT: 'XPLANVERFAHREN_IMPORT',
    X_PLAN_BOX: 'X_PLAN_BOX'
} as const;
export type GetProtokolleTyp = typeof GetProtokolleTyp[keyof typeof GetProtokolleTyp];
