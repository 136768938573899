/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.144.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { CodeZustaendigkeit } from './code-zustaendigkeit';

/**
 * 
 * @export
 * @interface NutzerRest
 */
export interface NutzerRest {
    /**
     * ID des Nutzers
     * @type {string}
     * @memberof NutzerRest
     */
    'nutzerID'?: string;
    /**
     * kompletter Name des Nutzers
     * @type {string}
     * @memberof NutzerRest
     */
    'vollerName'?: string;
    /**
     * E-Mail Adresse des Nutzers
     * @type {string}
     * @memberof NutzerRest
     */
    'email'?: string;
    /**
     * Rechte des Nutzers
     * @type {Set<string>}
     * @memberof NutzerRest
     */
    'rechte'?: Set<NutzerRestRechte>;
    /**
     * Cockpit Einstellungen z.B. für Sortierung (json Struktur)
     * @type {string}
     * @memberof NutzerRest
     */
    'cockpitSettings'?: string;
    /**
     * 
     * @type {CodeZustaendigkeit}
     * @memberof NutzerRest
     */
    'zustaendigkeit'?: CodeZustaendigkeit;
    /**
     * Rollen des Nutzers
     * @type {Array<string>}
     * @memberof NutzerRest
     */
    'rollen'?: Array<string>;
}

export const NutzerRestRechte = {
    ADMIN: 'ADMIN',
    VERFAHREN_READ: 'VERFAHREN_READ',
    VERFAHREN_WRITE: 'VERFAHREN_WRITE',
    VERFAHREN_ABONNIEREN: 'VERFAHREN_ABONNIEREN',
    EIGENE_VERFAHREN_READ: 'EIGENE_VERFAHREN_READ',
    EIGENE_VERFAHREN_WRITE: 'EIGENE_VERFAHREN_WRITE',
    VERTRAG_READ: 'VERTRAG_READ',
    VERTRAG_WRITE: 'VERTRAG_WRITE',
    EIGENE_VERTRAG_READ: 'EIGENE_VERTRAG_READ',
    EIGENE_VERTRAG_WRITE: 'EIGENE_VERTRAG_WRITE',
    DOKUMENT_READ: 'DOKUMENT_READ',
    DOKUMENT_WRITE: 'DOKUMENT_WRITE',
    EIGENE_DOKUMENT_READ: 'EIGENE_DOKUMENT_READ',
    EIGENE_DOKUMENT_WRITE: 'EIGENE_DOKUMENT_WRITE',
    VORLAGE_READ: 'VORLAGE_READ',
    VORLAGE_WRITE: 'VORLAGE_WRITE',
    STELLUNGNAHME_READ: 'STELLUNGNAHME_READ',
    STELLUNGNAHME_WRITE: 'STELLUNGNAHME_WRITE',
    EIGENE_STELLUNGNAHME_READ: 'EIGENE_STELLUNGNAHME_READ',
    EIGENE_STELLUNGNAHME_WRITE: 'EIGENE_STELLUNGNAHME_WRITE',
    REPORT: 'REPORT',
    EIGENE_REPORT: 'EIGENE_REPORT',
    VERFAHRENSSTEUERUNG_READ: 'VERFAHRENSSTEUERUNG_READ',
    VERFAHRENSSTEUERUNG_WRITE: 'VERFAHRENSSTEUERUNG_WRITE',
    VERFAHREN_DELETE: 'VERFAHREN_DELETE',
    EIGENE_VERFAHREN_DELETE_SIMULIERTES_VERFAHREN: 'EIGENE_VERFAHREN_DELETE_SIMULIERTES_VERFAHREN',
    VERFAHREN_CREATE: 'VERFAHREN_CREATE',
    EIGENE_VERFAHREN_CREATE: 'EIGENE_VERFAHREN_CREATE',
    PLANWERKE_READ: 'PLANWERKE_READ',
    PLANWERKE_WRITE: 'PLANWERKE_WRITE',
    EIGENE_PLANWERKE_READ: 'EIGENE_PLANWERKE_READ',
    EIGENE_PLANWERKE_WRITE: 'EIGENE_PLANWERKE_WRITE',
    BUERO_READ: 'BUERO_READ',
    BUERO_WRITE: 'BUERO_WRITE',
    BUERO_DELETE: 'BUERO_DELETE',
    VERFAHREN_EINSTELLUNGEN: 'VERFAHREN_EINSTELLUNGEN',
    VERFAHREN_DELETE_SIMULIERTES_VERFAHREN: 'VERFAHREN_DELETE_SIMULIERTES_VERFAHREN',
    VERFAHREN_BERECHTIGUNG_READ: 'VERFAHREN_BERECHTIGUNG_READ',
    VERFAHREN_BERECHTIGUNG_WRITE: 'VERFAHREN_BERECHTIGUNG_WRITE',
    EIGENE_VERFAHREN_BERECHTIGUNG_READ: 'EIGENE_VERFAHREN_BERECHTIGUNG_READ',
    EIGENE_VERFAHREN_BERECHTIGUNG_WRITE: 'EIGENE_VERFAHREN_BERECHTIGUNG_WRITE',
    VERFAHREN_SUCHE: 'VERFAHREN_SUCHE',
    EXTERNES_PLANUNGSBUERO: 'EXTERNES_PLANUNGSBUERO'
} as const;

export type NutzerRestRechte = typeof NutzerRestRechte[keyof typeof NutzerRestRechte];


