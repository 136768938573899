import moment from "moment";
import objectHash from "object-hash";
import { defineStore } from "pinia";

import { filter } from "@/services/object.js";
import type {
  CodeNichtMonitoringGrund,
  CodeVerfahrensschritt,
  CodeZustaendigkeit,
  GetVerfahrenProtokolleStatus,
  GetVerfahrenProtokolleTyp,
  NutzerVerfahrenRest,
  PageProtokollRest,
  TerminDetailRest,
  VerfahrenDetailRest,
  VerfahrenEinfacheSucheRest,
  VerfahrenNeuRest,
  VerfahrensKonfigurationRest,
  VerfahrensschrittKonfigurationRest,
  VerfahrensteilschrittKonfigurationRest,
  VerfahrensteilschrittRest,
  VerfahrenUebersichtRest,
} from "@/services/open-api";
import { openAPIFactory } from "@/services/open-api.js";
import { NutzerRestRechte } from "@/services/open-api/models/nutzer-rest.ts";
import {
  addUserProceedings,
  checkIfProceedingIsSimulated,
  getProceedingConfiguration,
  getProceedingDetail,
  removeUserProceedings,
} from "@/services/proceedings";
import { CockpitError, useAppStore } from "@/stores/app.ts";
import { useFundamentalStore } from "@/stores/fundamental.ts";

export interface SearchResult extends VerfahrenEinfacheSucheRest {
  isSimulated?: boolean;
}

interface VerfahrensteilschrittKonfiguration extends VerfahrensteilschrittKonfigurationRest {
  name: string;
  codeVerfahrensschritt: CodeVerfahrensschritt;
  stepIndex: number;
}

interface VerfahrensschrittKonfiguration {
  [key: string]: VerfahrensteilschrittKonfiguration;
}

export interface ProceedingStructureItem {
  name: string;
  phaseIndex: number;
  stepsBefore: number;
  maxSteps: number;
  [key: string]: VerfahrensschrittKonfiguration | string | number;
}

export interface ProceedingStructures {
  [key: string]: ProceedingStructureItem;
}

export interface CurrentProceeding {
  backup?: VerfahrenDetailRest;
  backupChecksum?: string;
  workingCopy?: VerfahrenDetailRest;
  loading?: boolean;
}

export interface ProceedingsStoreState {
  currentProceeding: CurrentProceeding | undefined;
  proceedingConfiguration: {
    [key: string]: VerfahrensKonfigurationRest;
  };
  proceedingParallelverfahren: {
    [key: string]: {
      loading: boolean;
      parallelverfahren: VerfahrenDetailRest[] | [];
    };
  };
  subscribedProceedings: {
    [key: string]: VerfahrenUebersichtRest;
  };
  subscribedProceedingIDs: string[];
  searchResults: SearchResult[];
  protocolsData: {
    planID: string;
    protocolsContent: PageProtokollRest;
  }[];
  errors: Set<string>;
  plannameChangeData: {
    isPlannameValid: boolean;
    errorMessage: string;
    isModalToBeShownAfterUnpublish: boolean;
  };
  selectedProceedingForNameChange: VerfahrenDetailRest;
}

export const useProceedingsStore = defineStore("proceedings", {
  state: (): ProceedingsStoreState => ({
    currentProceeding: undefined,
    proceedingConfiguration: {},
    proceedingParallelverfahren: {},
    subscribedProceedings: {},
    subscribedProceedingIDs: [],
    searchResults: [],
    protocolsData: [],
    errors: new Set(),
    plannameChangeData: {
      isPlannameValid: true,
      errorMessage: "",
      isModalToBeShownAfterUnpublish: false,
    },
    selectedProceedingForNameChange: {},
  }),
  actions: {
    /**
     * Performs a search of proceedings matching given criteria.
     * @param searchOptions Criteria defined for the search.
     */
    searchProceedings(searchOptions: object): Promise<VerfahrenEinfacheSucheRest[]> {
      const appStore = useAppStore();
      const optionsBlueprint = {
        name: undefined,
        plannameArbeitstitel: undefined,
        ehemaligerPlanname: undefined,
        bueroId: undefined,
        besitzer: undefined,
        codePlanstatus: undefined,
        codeZustaendigkeit: undefined,
        codeBezirk: undefined,
        codeGebietseinheit: undefined,
        codePlanart: undefined,
        codeVerfahrenssteuerung: undefined,
        codeVerfahrensstand: undefined,
        codeGebaeudeart: undefined,
        codeWettbewerbsart: undefined,
        options: undefined,
      };
      const definedOptions = { ...optionsBlueprint, ...searchOptions };
      const {
        name,
        plannameArbeitstitel,
        ehemaligerPlanname,
        bueroId,
        besitzer,
        codePlanstatus,
        codeZustaendigkeit,
        codeBezirk,
        codeGebietseinheit,
        codePlanart,
        codeVerfahrenssteuerung,
        codeVerfahrensstand,
        codeGebaeudeart,
        codeWettbewerbsart,
        options,
      } = definedOptions;

      appStore.showPageLoadingIndicator({
        id: "searchProceedings",
        text: "Einen Moment bitte, die Suche wird ausgeführt.",
      });

      return new Promise((resolve, reject) => {
        openAPIFactory
          .verfahrenResourceApiFactory()
          .einfacheSucheVerfahren(
            name,
            plannameArbeitstitel,
            ehemaligerPlanname,
            bueroId,
            besitzer,
            codePlanstatus,
            codeZustaendigkeit,
            codeBezirk,
            codeGebietseinheit,
            codePlanart,
            codeVerfahrenssteuerung,
            codeVerfahrensstand,
            codeGebaeudeart,
            codeWettbewerbsart,
            options,
          )
          .then((response) => {
            this.searchResults = response.data;

            appStore.hidePageLoadingIndicator("searchProceedings");

            resolve(response.data);
          })
          .catch((error) => {
            appStore.hidePageLoadingIndicator("searchProceedings");

            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Die Suche nach Verfahren ist fehlgeschlagen!",
            });

            reject(error);
          });
      });
    },
    /**
     * Loads all proceedings the current user is subscribed to and caches the data.
     */
    async loadSubscribedProceedings(forceReload = false) {
      const appStore = useAppStore();
      const fundamentalStore = useFundamentalStore();
      const rechte = fundamentalStore.userSettings.rechte;

      // TODO: remove resolved in cleanup after clientcache refactoring

      return new Promise((resolve, reject) => {
        if (
          !fundamentalStore.checkUserPermission(NutzerRestRechte.VERFAHREN_READ) &&
          !fundamentalStore.checkUserPermissionAndResponsibility(
            NutzerRestRechte.EIGENE_VERFAHREN_READ,
            this.currentProceedingWorkingCopy?.zustaendigkeit?.code,
          ) &&
          !fundamentalStore.checkUserPermission(NutzerRestRechte.ADMIN)
        ) {
          appStore.resolved["/verfahren"] = true;
          resolve([]);
          return;
        }

        if (!appStore.resolved["/verfahren"] || forceReload) {
          appStore.showPageLoadingIndicator({
            id: "loadSubscribedProceedings",
            text: "Einen Moment bitte, ihre Verfahren werden geladen.",
          });

          openAPIFactory
            .verfahrenResourceApiFactory()
            .getAllVerfahren()
            .then((response) => {
              // reset state
              this.subscribedProceedings = {};

              response.data
                .filter((proceeding) => proceeding.planstatus?.code !== "0110")
                .forEach((proceeding) => {
                  if (proceeding.planID !== undefined) {
                    this.subscribedProceedings[proceeding.planID] = proceeding;
                  }
                });

              appStore.hidePageLoadingIndicator("loadSubscribedProceedings");

              appStore.resolved["/verfahren"] = true;
              resolve(response.data);
            })
            .catch((error) => {
              appStore.hidePageLoadingIndicator("loadSubscribedProceedings");

              if (error.response.status === 403 && rechte.includes("EXTERNES_PLANUNGSBUERO")) {
                appStore.resolved["/verfahren"] = true;
                resolve([]);
                return;
              }

              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Laden der Übersicht fehlgeschlagen!",
              });
              reject(error);
            });
        } else {
          resolve(undefined);
        }
      });
    },
    /**
     * Loads all proceeding IDs the current user is subscribed to and caches the data.
     */
    async loadSubscribedProceedingIDs(forceReload = false) {
      const appStore = useAppStore();

      // TODO: remove resolved in cleanup after clientcache refactoring
      return new Promise((resolve, reject) => {
        if (!appStore.resolved["/nutzer/verfahren"] || forceReload) {
          appStore.showPageLoadingIndicator({
            id: "bootstrap",
            text: "Einen Moment bitte, die Anwendung wird geladen.",
          });

          openAPIFactory
            .nutzerResourceApiFactory()
            .getNutzerVerfahren()
            .then((response) => {
              if (response.data) {
                this.subscribedProceedingIDs = response.data
                  .filter((entry) => typeof entry.planID === "string")
                  .map((entry) => entry.planID) as string[];

                appStore.resolved["/nutzer/verfahren"] = true;

                resolve(response.data);
              }
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Laden der Übersicht fehlgeschlagen!",
              });
              reject(error);
            })
            .finally(() => {
              appStore.hidePageLoadingIndicator("bootstrap");
            });
        } else {
          resolve(undefined);
        }
      });
    },
    /**
     * Loads a specific proceeding and caches the data.
     * If no ID is given the ID is derived from the path, if the ID is provided encapsulated
     * within an object, a forced reload is done.
     */
    loadProceedingByID(
      proceedingID: string | undefined,
      forceReload?: boolean,
    ): Promise<false | VerfahrenDetailRest> {
      const appStore = useAppStore();

      if (!proceedingID) {
        return new Promise((reject) => {
          appStore.showErrorModal({
            response: {
              data: {
                message: "Die ID des Verfahrens konnte nicht ermittelt werden.",
              },
            },
            customErrorMessage: "Laden des Verfahrens fehlgeschlagen!",
          });

          reject(false);
        });
      }

      if (
        !this.currentProceeding?.loading &&
        (this.currentProceedingWorkingCopy?.planID !== proceedingID || forceReload)
      ) {
        this.currentProceeding = {
          ...this.currentProceeding,
          loading: true,
        };

        return new Promise((resolve, reject) => {
          getProceedingDetail(proceedingID as string)
            .then((proceeding) => {
              this.updateCurrentProceeding(proceeding);

              resolve(proceeding);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              if (this.currentProceeding) {
                this.currentProceeding.loading = false;
              }
            });
        });
      }

      return Promise.resolve(false);
    },

    /**
     * Loads the configurations of the specified proceedings.
     * @param proceedingIDs
     */
    async loadProceedingConfigurationsByIDs(proceedingIDs: string[]) {
      const appStore = useAppStore();
      let promises: Promise<VerfahrensKonfigurationRest>[] = [];

      proceedingIDs.forEach((proceedingID) => {
        if (!this.proceedingConfiguration[proceedingID]) {
          appStore.showPageLoadingIndicator({
            id: "loadProceedingConfiguration",
            text: "Einen Moment bitte, die Verfahrenskonfiguration wird geladen.",
          });
          promises.push(
            new Promise((resolve, reject) => {
              getProceedingConfiguration(proceedingID)
                .then((response) => {
                  this.proceedingConfiguration[proceedingID] = response;
                  resolve(response);
                })
                .catch((error) => {
                  appStore.hidePageLoadingIndicator("loadProceedingConfiguration");
                  appStore.showErrorModal({
                    response: error,
                    customErrorMessage: "Laden der Verfahrenskonfiguration fehlgeschlagen!",
                  });
                  reject(error);
                })
                .finally(() => {
                  appStore.hidePageLoadingIndicator("loadProceedingConfiguration");
                });
            }),
          );
        }
      });
      await Promise.all(promises);
    },
    /**
     * Loads all parallel proceedings of a specific proceeding.
     * @param proceedingID ID of the plan to load all parallel proceedings
     */

    /**
     * Reset current proceeding data
     */
    resetCurrentProceeding() {
      this.currentProceeding = undefined;
    },
    /**
     * Loads parallel proceedings for a specific proceeding.
     * @param parallelProceedingIds {string[]}- array of parallel proceeding IDs
     * @param proceedingID {string} - proceeding ID to which the parallel proceedings refer
     * @param forceReload {boolean} - whether to use preloaded data
     */
    loadParallelProceedingsForID(
      parallelProceedingIds: string[],
      proceedingID: string,
      forceReload = false,
    ): Promise<VerfahrenDetailRest[] | false> {
      const appStore = useAppStore();
      const promiseList: Promise<VerfahrenDetailRest>[] = [];

      if (proceedingID) {
        // set default
        this.proceedingParallelverfahren[proceedingID] = {
          loading: false,
          parallelverfahren:
            this.proceedingParallelverfahren[proceedingID]?.parallelverfahren || [],
        };

        // preloaded parallelProceedings data
        const parallelProceedings = this.parallelProceedingsByProceedingID(proceedingID);

        // check if all parallelProceedings have been loaded previously
        if (
          !forceReload &&
          parallelProceedings.length === parallelProceedingIds.length &&
          parallelProceedings.every(
            (proceeding: VerfahrenDetailRest) =>
              proceeding.planID && parallelProceedingIds.includes(proceeding.planID),
          )
        ) {
          return new Promise((resolve) => {
            resolve(parallelProceedings);
          });
        }

        appStore.showPageLoadingIndicator({
          id: "loadParallelProceeding",
          text: "Einen Moment bitte, die zugehörigen Parallelverfahren werden geladen.",
        });

        parallelProceedingIds.forEach((pvID: string) => {
          promiseList.push(
            new Promise((resolve, reject) => {
              this.proceedingParallelverfahren[proceedingID].loading = true;

              openAPIFactory
                .verfahrenResourceApiFactory()
                .getVerfahrenDetail(pvID)
                .then((response) => {
                  resolve(response.data);
                })
                .catch((error) => {
                  reject(error);
                })
                .finally(() => appStore.hidePageLoadingIndicator("loadParallelProceeding"));
            }),
          );
        });

        return Promise.all(promiseList)
          .then((response) => {
            // here we DO NOT want to update the complete object as done in "cacheProceeding"
            // otherwise the GraphMilestoneGraphic Overview will be completely rerendered for each toggle of parallel proceedings badge
            if (this.proceedingParallelverfahren[proceedingID].loading) {
              this.proceedingParallelverfahren[proceedingID].parallelverfahren = response;
              this.proceedingParallelverfahren[proceedingID].loading = false;
            }

            return this.parallelProceedingsByProceedingID(proceedingID);
          })
          .catch((error) => {
            this.proceedingParallelverfahren[proceedingID].loading = false;

            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Laden der Parallelverfahren fehlgeschlagen!",
            });

            return [];
          })
          .finally(() => appStore.hidePageLoadingIndicator("loadParallelProceeding"));
      }

      return Promise.resolve(false);
    },
    /**
     * Load configuration of proceeding if it has not been loaded yet
     * (note: a proceeding configuration does not change)
     * @param proceedingID
     * @param forceReload
     */
    loadProceedingConfiguration(
      proceedingID: string,
      forceReload = false,
    ): Promise<false | VerfahrensKonfigurationRest> {
      if (this.proceedingConfiguration[proceedingID] && !forceReload) {
        return Promise.resolve(false);
      }
      return new Promise((resolve, reject) => {
        getProceedingConfiguration(proceedingID)
          .then((configuration) => {
            this.proceedingConfiguration[proceedingID] = configuration;
            resolve(configuration);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /**
     * Creates a new proceeding.
     * @param payload
     * @param payload.newProceeding The data object defining the new proceeding.
     * @param payload.file The GML document.
     */
    createProceeding(payload: {
      newProceeding: VerfahrenNeuRest;
      file: File;
    }): Promise<false | string> {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "createProceeding",
        text: "Einen Moment bitte, ihr Verfahren wird angelegt.",
      });

      return new Promise((resolve, reject) => {
        openAPIFactory
          .verfahrenResourceApiFactory()
          .createVerfahren(payload.newProceeding, payload.file)
          .then((response) => {
            const proceeding = response.data;

            if (proceeding.planID) {
              resolve(proceeding.planID);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Erstellen des Verfahrens fehlgeschlagen!",
            });

            reject(error);
          })
          .finally(() => {
            appStore.hidePageLoadingIndicator("createProceeding");
          });
      });
    },
    /**
     * Deletes a proceeding with the specified plan ID.
     * @param planID The ID of the proceeding to be deleted.
     * @param force
     * @returns A promise that resolves with the response data upon successful deletion.
     */
    deleteProceeding(planID: string, force: boolean): Promise<string> {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "deleteProceeding",
        text: "Moment. Ihr Planverfahren wird gelöscht.",
      });

      return new Promise((resolve, reject) => {
        openAPIFactory
          .verfahrenResourceApiFactory()
          .deleteVerfahren(planID, force)
          .then((response) => {
            this.resetCurrentProceeding();

            this.subscribedProceedingIDs = this.subscribedProceedingIDs.filter(
              (element) => element !== planID,
            );

            this.subscribedProceedings = filter(
              this.subscribedProceedings,
              (element: VerfahrenUebersichtRest, key: string) => key !== planID,
            ) as {
              [key: string]: VerfahrenUebersichtRest;
            };

            resolve(response.data);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Das Löschen des Planverfahrens ist fehlgeschlagen!",
            });

            reject(error);
          })
          .finally(() => {
            appStore.hidePageLoadingIndicator("deleteProceeding");
          });
      });
    },
    /**
     * Updates a specific proceeding.
     * @param payload
     * @param payload.proceedingData The proceeding data that should get saved.
     * @param payload.proceedingID The ID of the proceeding.
     * @returns Returns a promise that gets resolved or rejected based upon the response of the backend.
     */
    saveProceedingByID(payload: {
      proceedingData: object;
      proceedingID: string;
    }): Promise<VerfahrenDetailRest> {
      const appStore = useAppStore();

      if (this.currentProceeding) {
        this.currentProceeding.loading = true;
      }

      return new Promise((resolve, reject) => {
        openAPIFactory
          .verfahrenResourceApiFactory()
          .updateVerfahren(payload.proceedingData, payload.proceedingID)
          .then((response) => {
            const proceeding = response.data;

            this.updateCurrentProceeding(proceeding);

            resolve(proceeding);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Das Speichern der Änderungen ist fehlgeschlagen!",
            });
            if (this.currentProceeding) {
              this.currentProceeding.loading = false;
            }

            reject(error);
          });
      });
    },
    /**
     * Loads the protocols of the proceeding.
     * @param payload
     * @param payload.planID The identifier of the proceeding.
     * @param payload.filterParams The filter params.
     * @param payload.pageable Pagination options.
     */
    loadProceedingProtocols(payload: {
      planID: string;
      filterParams: {
        Status: GetVerfahrenProtokolleStatus[];
        Typ: GetVerfahrenProtokolleTyp[];
        Beschreibung: string[];
        Datum: string[];
        "Bearbeitet von": string[];
      };
      pageable: {
        page: number;
        size: number;
        sort: string[];
      };
    }): Promise<PageProtokollRest | false> {
      const planID = payload.planID;

      if (planID) {
        return new Promise((resolve, reject) => {
          const status = payload.filterParams.Status.length
            ? payload.filterParams.Status
            : undefined;
          const typ = payload.filterParams.Typ.length ? payload.filterParams.Typ : undefined;
          const beschreibung = payload.filterParams.Beschreibung.length
            ? payload.filterParams.Beschreibung[0]
            : undefined;
          const erfassungsdatum = payload.filterParams.Datum.length
            ? moment(payload.filterParams.Datum[0], "DD.MM.YYYY").format("YYYY-MM-DD")
            : undefined;
          const erfasser = payload.filterParams["Bearbeitet von"].length
            ? payload.filterParams["Bearbeitet von"][0]
            : undefined;

          openAPIFactory
            .verfahrenResourceApiFactory()
            .getVerfahrenProtokolle(
              planID,
              status,
              typ,
              beschreibung,
              erfasser,
              erfassungsdatum,
              payload.pageable.page,
              payload.pageable.size,
              payload.pageable.sort,
            )
            .then((response) => {
              const protocolsContent = response.data;
              const protocolsData = [...this.protocolsData];
              const index = protocolsData.findIndex((protocol) => protocol.planID === planID);

              if (index !== -1) {
                protocolsData[index] = { planID, protocolsContent };
              } else {
                protocolsData.push({
                  planID,
                  protocolsContent,
                });
              }

              this.protocolsData = protocolsData;

              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Resets the working copy of the current proceeding to its unaltered state.
     */
    resetCurrentProceedingWorkingCopy() {
      if (!this.currentProceeding) return;

      const proceedingData = { ...this.currentProceeding };

      proceedingData.workingCopy = JSON.parse(JSON.stringify(this.currentProceeding?.backup));

      this.currentProceeding = proceedingData;
    },
    /**
     * Update data for proceeding details
     * @param proceeding The proceedings data object.
     */
    updateCurrentProceeding(proceeding: VerfahrenDetailRest) {
      if (proceeding.planID) {
        this.currentProceeding = {
          loading: false,
          workingCopy: proceeding,
          backup: JSON.parse(JSON.stringify(proceeding)),
          backupChecksum: objectHash.sha1(proceeding),
        };
      } else {
        this.currentProceeding = undefined;
      }
    },
    /**
     * Adds or removes an error from the errors set.
     * @param payload Object containing error info
     */
    handleErrors(payload: { hasError: boolean; id: string }) {
      // if error exists add it to the set otherwise delete it from the set
      if (payload.hasError) {
        this.errors.add(payload.id);
      } else {
        this.errors.delete(payload.id);
      }
    },
    /**
     * Resets all errors of the errors set.
     */
    resetErrors() {
      this.errors.clear();
    },
    /**
     * set the Data selected for planname Change
     */
    setProceedingsDataSelectedForPlannameChange(selectedProceedingsData: VerfahrenDetailRest) {
      this.selectedProceedingForNameChange = { ...selectedProceedingsData };
    },
    /**
     * set the Error State for planname Change
     */
    setErrorStateForPlannameChange(isPlannameValid: boolean, errorMessage: string) {
      this.plannameChangeData.isPlannameValid = isPlannameValid;
      this.plannameChangeData.errorMessage = errorMessage;
    },
    /**
     * set a flag if process is after unpublish
     */
    setFlagForPlannameChangeAfterUnpublish(modalAfterUnpublish: boolean) {
      this.plannameChangeData.isModalToBeShownAfterUnpublish = modalAfterUnpublish;
    },
    resetDataSelectedForPlannameChange() {
      this.selectedProceedingForNameChange = {};
    },
    resetPlannameChangeData() {
      this.plannameChangeData = {
        isPlannameValid: true,
        errorMessage: "",
        isModalToBeShownAfterUnpublish: false,
      };
    },
    /**
     * Subscribe to proceeding by planID and update list "subscribedProceedingIDs"
     * @param planID - proceeding ID
     * @param showIndication - whether to show indication modal on subscription update
     */
    async addSubscribedProceeding(planID: string, showIndication = false) {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "subscribeProceeding",
        text: "Einen Moment bitte, das Verfahren wird abonniert...",
      });

      try {
        const response = await addUserProceedings(planID);

        if (response) {
          const { list, abonnierungGeandert } = response;

          if (abonnierungGeandert && showIndication) {
            appStore.showIndicationModal({
              status: "success",
              info: "Das Verfahren wurde Ihrer Liste abonnierter Verfahren hinzugefügt.",
              buttontext: "Schließen",
              headline: "Verfahren abonnieren",
            });
          }

          this.subscribedProceedingIDs = list.map((item: NutzerVerfahrenRest) => item.planID);

          // TODO: remove resolved in cleanup after clientcache refactoring
          appStore.resolved["/nutzer/verfahren"] = true;
        }
      } catch (error) {
        appStore.showErrorModal({
          response: error as CockpitError,
          customErrorMessage: "Aktualisierung der abonnierten Verfahren fehlgeschlagen!",
        });
      } finally {
        appStore.hidePageLoadingIndicator("subscribeProceeding");
      }
    },
    /**
     * Remove proceeding from subscription and update list "subscribedProceedingIDs"
     * @param planID - proceeding ID
     */
    async removeSubscribedProceeding(planID: string) {
      const appStore = useAppStore();

      appStore.showPageLoadingIndicator({
        id: "unsubscribeProceeding",
        text: "Einen Moment bitte, das Verfahren wird deabonniert...",
      });

      try {
        const response = await removeUserProceedings(planID);

        if (response) {
          const { list } = response;

          this.subscribedProceedingIDs = list.map((item: NutzerVerfahrenRest) => item.planID);

          // TODO: remove resolved in cleanup after clientcache refactoring
          appStore.resolved["/nutzer/verfahren"] = true;
        }
      } catch (error) {
        appStore.showErrorModal({
          response: error as CockpitError,
          customErrorMessage: "Aktualisierung der abonnierten Verfahren fehlgeschlagen!",
        });
      } finally {
        appStore.hidePageLoadingIndicator("unsubscribeProceeding");
      }
    },
  },
  getters: {
    /**
     * Returns an array of all subscribed proceedings
     @returns All subscribed proceedings
     */
    subscribedProceedingsAsList(): VerfahrenUebersichtRest[] {
      const fundamentalStore = useFundamentalStore();

      let proceedingsList: VerfahrenUebersichtRest[] = [];

      Object.values(this.subscribedProceedings).forEach((proceeding: VerfahrenUebersichtRest) => {
        // check if proceeding is still subscribed
        if (
          proceeding.planID &&
          (this.proceedingIDIsSubscribed(proceeding.planID) ||
            fundamentalStore.checkUserPermission(NutzerRestRechte.EXTERNES_PLANUNGSBUERO))
        ) {
          proceedingsList.push(proceeding);
        }
      });

      return proceedingsList;
    },
    /**
     * Returns an array of all subscribed proceedings, ordered by the user's preference.
     * @returns All subscribed proceedings in the desired order.
     */
    subscribedSortedProceedings(): VerfahrenUebersichtRest[] {
      const fundamentalStore = useFundamentalStore();
      const sortingOrder = fundamentalStore.userSettings.cockpitSettings.sortingOrder;
      const ordered: VerfahrenUebersichtRest[] = [];
      // Make sure the stores is write-protected from changes to this array
      let proceedings: VerfahrenUebersichtRest[] = [...this.subscribedProceedingsAsList];

      proceedings.sort((a, b) => {
        const aName = a.planname ? a.planname : a.arbeitstitel ? a.arbeitstitel : "";
        const bName = b.planname ? b.planname : b.arbeitstitel ? b.arbeitstitel : "";

        return aName.localeCompare(bName);
      });

      switch (sortingOrder) {
        case "AKTIV_SIMULIERT":
        case "SIMULIERT_AKTIV": {
          // Aktive Verfahren oben, simulierte unten
          // Simulierte Verfahren oben, aktive unten

          const simulated: VerfahrenUebersichtRest[] = [];
          const active: VerfahrenUebersichtRest[] = [];

          proceedings.forEach((proceeding) => {
            if (checkIfProceedingIsSimulated(proceeding)) {
              simulated.push(proceeding);
            } else {
              active.push(proceeding);
            }
          });

          return sortingOrder === "AKTIV_SIMULIERT"
            ? [...active, ...simulated]
            : [...simulated, ...active];
        }
        case "START_DATUM": {
          // Datum der Grobabstimmung

          const hasVTS = (proceeding: VerfahrenUebersichtRest): boolean =>
            Object.hasOwn(proceeding, "verfahrensteilschritte");

          const getVTS = (proceeding: VerfahrenUebersichtRest): TerminDetailRest | undefined => {
            const vts: VerfahrensteilschrittRest | undefined = (
              proceeding.verfahrensteilschritte as VerfahrensteilschrittRest[]
            )?.find((vts) => vts.codeVerfahrensschritt?.code === "2000");

            return vts && vts.termine
              ? vts.termine.find((termin) => termin.beschreibung === "Grobabstimmung")
              : undefined;
          };

          const getDate = (termin?: TerminDetailRest) =>
            termin?.geplanterOderBerechneterZeitraum?.ende
              ? new Date(termin.geplanterOderBerechneterZeitraum.ende).getTime()
              : -1;

          proceedings.sort((a, b) => {
            const aVTS = hasVTS(a) ? getVTS(a) : undefined;
            const bVTS = hasVTS(b) ? getVTS(b) : undefined;

            const aDate = getDate(aVTS);
            const bDate = getDate(bVTS);

            return aDate > bDate ? 1 : -1;
          });

          return proceedings;
        }
        case "CUSTOM": {
          const proceedingOrder =
            fundamentalStore.userSettings.cockpitSettings.customProceedingOrder;

          proceedingOrder.forEach((planID) => {
            const index = proceedings.findIndex((proceeding) => proceeding.planID === planID);

            if (index > -1) {
              const add = proceedings.splice(index, 1).shift();

              if (add) {
                ordered.push(add);
              }
            }
          });

          return [...ordered, ...proceedings];
        }
        case "VERFAHRENSSTAND": {
          // Verfahrensstand

          const statusOrder = [
            "2000", //imVerfahren
            "1000", //simuliert
            "3000", //festgestellt
            "6000", //laufendesNormenkontrollverfahren
            "4000", //ganzAufgehoben
            "5000", //eingestellt
          ];

          statusOrder.forEach((status) => {
            ordered.push(
              ...proceedings.filter((proceeding) => {
                const verfahrensstand = proceeding.verfahrensstand
                  ? proceeding.verfahrensstand.code
                  : "";

                return verfahrensstand === status;
              }),
            );
          });

          ordered.push(
            ...proceedings.filter((proceeding) => {
              return proceeding.verfahrensstand === null;
            }),
          );

          return ordered;
        }
        case "PLANNAME": // Name des Verfahrens
        default:
          return proceedings;
      }
    },
    /**
     * Checks if a proceeding is subscribed.
     * @returns The result of the check.
     */
    proceedingIDIsSubscribed(): (proceedingID: string) => boolean {
      return (proceedingID) => {
        if (proceedingID) {
          return this.subscribedProceedingIDs.includes(proceedingID);
        }

        return false;
      };
    },

    /**
     * Returns data of a specific subscribed proceeding.
     * @returns The data of the subscribed proceeding.
     */
    subscribedProceedingByID(): (proceedingID: string) => VerfahrenUebersichtRest | undefined {
      return (proceedingID) => {
        if (
          proceedingID !== undefined &&
          typeof this.subscribedProceedings[proceedingID] !== "undefined"
        ) {
          return { ...this.subscribedProceedings[proceedingID] };
        }

        return undefined;
      };
    },

    /**
     * Checks if current proceeding is defined
     */
    currentProceedingIsDefined(): boolean {
      return typeof this.currentProceeding !== "undefined";
    },

    /**
     * Returns non-reactive working copy of the current proceeding.
     * @returns The working copy of the proceeding.
     */
    currentProceedingWorkingCopy(): VerfahrenDetailRest | undefined {
      if (this.currentProceedingIsDefined && this.currentProceeding?.workingCopy) {
        return { ...this.currentProceeding.workingCopy };
      }

      return undefined;
    },

    /**
     * Returns reactive working copy of the current proceeding.
     * @returns The working copy of the proceeding.
     */
    currentProceedingWorkingCopyReactive(): VerfahrenDetailRest | undefined {
      if (this.currentProceedingIsDefined && this.currentProceeding?.workingCopy) {
        return this.currentProceeding.workingCopy;
      }

      return undefined;
    },

    /**
     * Returns the planID of the current proceeding.
     * @returns The planID of the proceeding.
     */
    currentProceedingPlanID(): string | undefined {
      return this.currentProceedingWorkingCopy?.planID;
    },

    /**
     * Checks whether the current proceeding is simulated.
     */
    currentProceedingIsSimulated(): boolean {
      const workingCopy = this.currentProceedingWorkingCopy;

      if (workingCopy) {
        return checkIfProceedingIsSimulated(workingCopy);
      }

      return false;
    },

    /**
     * checks whether the state of the current proceeding working copy has been altered.
     */
    currentProceedingWorkingCopyHasBeenAltered(): boolean {
      const workingCopy = this.currentProceedingWorkingCopy;

      if (workingCopy) {
        return objectHash.sha1(workingCopy) !== this.currentProceeding?.backupChecksum;
      }

      return false;
    },

    /**
     * Checks whether 'hatZeitplanung' is true for the current proceeding.
     */
    currentProceedingHasSchedule(): boolean {
      return this.currentProceedingWorkingCopy?.hatZeitplanung ?? false;
    },

    /**
     * Checks if the current proceeding is predictable.
     */
    currentProceedingIsPredictable(): boolean {
      return this.currentProceedingWorkingCopy?.prognostizierbar ?? false;
    },

    /**
     * Checks whether 'verfahrensstand' has changed for the current proceeding.
     */
    currentProceedingStatusHasChanged(): boolean {
      if (this.currentProceeding?.backup) {
        return (
          this.currentProceedingWorkingCopy?.verfahrensstand?.code !==
          this.currentProceeding.backup?.verfahrensstand?.code
        );
      }

      return false;
    },

    /**
     * Returns the Not monitoring reasons of a specific proceeding.
     * @returns Array of Not monitoring reasons.
     */
    notMonitoringReasonsByProceedingID(): (
      proceedingID: string,
    ) => CodeNichtMonitoringGrund[] | undefined {
      return (proceedingID) => {
        if (proceedingID && this.proceedingConfiguration[proceedingID]) {
          return this.proceedingConfiguration[proceedingID].nichtMonitoringGruende;
        }
        return undefined;
      };
    },

    /**
     * Returns a function retrieving the parallel proceedings of a proceeding defined by its ID.
     * @returns An array of parallel proceedings.
     */
    parallelProceedingsByProceedingID(): (proceedingID: string) => VerfahrenDetailRest[] {
      return (proceedingID) => {
        if (
          typeof this.proceedingParallelverfahren[proceedingID] !== "undefined" &&
          this.proceedingParallelverfahren[proceedingID].loading === false
        ) {
          return this.proceedingParallelverfahren[proceedingID].parallelverfahren || [];
        }

        return [];
      };
    },

    /**
     * Returns a function that returns proceeding structure of one or multiple planIDs
     */
    proceedingStructuresByProceedingID(): (planIDs: string | string[]) => ProceedingStructures {
      return (planIDs) => {
        const proceedingIDs = Array.isArray(planIDs) ? planIDs : [planIDs];

        const fundamentalStore = useFundamentalStore();

        const combinedProceedingStructures: ProceedingStructures = {};

        // Make sure that proceeding codelist is actually sorted as this codelist determines
        // the order of the proceeding phases displayed.
        const proceedingPhaseOrder = fundamentalStore
          .getCodelistByName("verfahrensschritte")
          .sort((a, b) => {
            return a.code && b.code && a.code < b.code ? -1 : 1;
          });

        // count all added vts steps
        let stepCounter = 0;

        // count phases
        let phaseCounter = 0;

        // Process each proceeding phase separately
        proceedingPhaseOrder.forEach((proceedingPhase) => {
          proceedingIDs.forEach((proceedingID) => {
            const proceedingConfiguration = this.proceedingConfiguration[proceedingID] ?? undefined;

            if (
              proceedingConfiguration?.verfahrenssteuerung?.code &&
              proceedingPhase.code &&
              proceedingPhase.name &&
              proceedingConfiguration?.verfahrensschritte &&
              proceedingConfiguration.verfahrensschritte.length > 0 &&
              // Check if the currently processed proceeding type features the current proceeding phase
              proceedingConfiguration.verfahrensschritte.find(
                (vts: VerfahrensschrittKonfigurationRest) =>
                  vts.codeVerfahrensschritt?.code === proceedingPhase.code,
              )
            ) {
              const vsStructure: VerfahrensschrittKonfigurationRest | undefined =
                proceedingConfiguration.verfahrensschritte.find(
                  (vts: VerfahrensschrittKonfigurationRest) =>
                    vts.codeVerfahrensschritt?.code === proceedingPhase.code,
                );

              const vtsStructure: VerfahrensschrittKonfiguration = {};

              // For ProView only: check if the vs does contain at least one vts
              if (
                vsStructure?.verfahrensteilschritte &&
                (!fundamentalStore.isProUser || vsStructure.verfahrensteilschritte.length > 0)
              ) {
                vsStructure.verfahrensteilschritte.forEach((vts, indexVS) => {
                  const vtsCode: string | undefined = vts?.codeVerfahrensteilschritt?.code;

                  if (
                    vtsCode &&
                    vts.codeVerfahrensteilschritt?.name &&
                    vsStructure.codeVerfahrensschritt
                  ) {
                    vtsStructure[vtsCode] = {
                      ...vts,
                      name: vts.codeVerfahrensteilschritt.name,
                      stepIndex: indexVS + 1,
                      codeVerfahrensschritt: vsStructure.codeVerfahrensschritt,
                    };
                  }
                });

                // Note: combinedProceedingStructures does match structure of fundamental store state "proceedingStructures"
                // 'stepsBefore','maxSteps','phaseIndex' are added here to match the fundamental store "proceedingStructures"
                // this will be necessary if we reuse this a global method to be used in AppProcessSteps.vue etc

                if (!combinedProceedingStructures[proceedingPhase.code]) {
                  combinedProceedingStructures[proceedingPhase.code] = {
                    name: proceedingPhase.name,
                    phaseIndex: phaseCounter,
                    stepsBefore: stepCounter,
                    maxSteps: vsStructure.verfahrensteilschritte.length,
                    [proceedingConfiguration.verfahrenssteuerung.code]: vtsStructure,
                  };
                } else if (
                  !combinedProceedingStructures[proceedingPhase.code][
                    proceedingConfiguration.verfahrenssteuerung.code
                  ]
                ) {
                  combinedProceedingStructures[proceedingPhase.code] = {
                    ...combinedProceedingStructures[proceedingPhase.code],
                    maxSteps:
                      combinedProceedingStructures[proceedingPhase.code].maxSteps >
                      vsStructure.verfahrensteilschritte.length
                        ? combinedProceedingStructures[proceedingPhase.code].maxSteps
                        : vsStructure.verfahrensteilschritte.length,
                    [proceedingConfiguration.verfahrenssteuerung.code]: vtsStructure,
                  };
                }
              }
            }
          });
          stepCounter +=
            proceedingPhase.code && combinedProceedingStructures[proceedingPhase.code]
              ? combinedProceedingStructures[proceedingPhase.code].maxSteps
              : 0;
          phaseCounter += 1;
        });

        return combinedProceedingStructures;
      };
    },
    /**
     * Returns the property zustaendigkeit of current proceeding
     * @returns property zustaendigkeit of current proceeding
     */
    currentProceedingZustaendigkeit(): CodeZustaendigkeit | undefined {
      return this.currentProceedingWorkingCopy?.zustaendigkeit;
    },
  },
});
