import { defineStore } from "pinia";

import type {
  CodeNichtMonitoringGrund,
  MonitoringStatusRest,
  TerminDetailRest,
  ZeitplanungRest,
} from "@/services/open-api";
import { openAPIFactory } from "@/services/open-api.js";
import { useAppStore } from "@/stores/app.ts";
import { useFundamentalStore } from "@/stores/fundamental.ts";
import { useTasksStore } from "@/stores/tasks.ts";

interface AppointmentContainer {
  [key: string]: TerminDetailRest[] | { loading?: boolean } | null | undefined;
}

export interface AppointmentsStoreState {
  appointmentContainer: AppointmentContainer;
  pvAppointmentContainer: AppointmentContainer;
  scheduleContainer: {
    [key: string]: {
      loading?: boolean;
      invalidated: boolean;
      monitoringAktiv: boolean;
      nichtMonitoringGrund?: CodeNichtMonitoringGrund;
      termine: TerminDetailRest[];
    };
  };
}

export const useAppointmentsStore = defineStore("appointments", {
  state: (): AppointmentsStoreState => ({
    appointmentContainer: {},
    pvAppointmentContainer: {},
    scheduleContainer: {},
  }),
  actions: {
    /**
     * Loads all appointments of a specific proceeding and caches it.
     * @param payload
     * @param payload.proceedingID The identifier of the proceeding.
     * @param payload.isPV Check if proceeding is only parallel proceeding and appointments need to be stored in different structure.
     * getScheduleRelevantAppointmentsById
     * */
    loadProceedingAppointmentsByID(payload: {
      proceedingID: string;
      forceReload: boolean;
      isPV?: boolean;
    }): Promise<TerminDetailRest[] | false> {
      const appStore = useAppStore();
      let pID: string;

      pID = payload.proceedingID;

      let isLoading = false;

      if (pID) {
        let appointmentContainerItem;

        if (payload.isPV) {
          appointmentContainerItem = this.pvAppointmentContainer[pID];
        } else {
          appointmentContainerItem = this.appointmentContainer[pID];
        }

        if (
          typeof appointmentContainerItem === "object" &&
          appointmentContainerItem !== null &&
          "loading" in appointmentContainerItem
        ) {
          isLoading = appointmentContainerItem.loading !== undefined;
        }
      }

      if (
        pID !== undefined &&
        (isLoading ||
          payload.forceReload ||
          (!payload.isPV && this.appointmentContainer[pID] === undefined) ||
          (payload.isPV && this.pvAppointmentContainer[pID] === undefined))
      ) {
        const planID = pID as string;

        if (!payload.isPV) {
          this.appointmentContainer[planID] = { loading: true };
        } else {
          this.pvAppointmentContainer[planID] = { loading: true };
        }

        return new Promise((resolve, reject) => {
          openAPIFactory
            .terminResourceApiFactory()
            .getZeitplanung(planID)
            .then((response) => {
              const termine: TerminDetailRest[] | undefined = response?.data?.termine;

              if (payload.isPV) {
                this.pvAppointmentContainer[planID] = termine;
              } else {
                this.appointmentContainer[planID] = termine;
              }

              resolve(termine as TerminDetailRest[]);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Die Ermittlung der Verfahrenstermine ist fehlgeschlagen!",
              });

              reject();
            });
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Loads the schedule relevant data of a specific proceeding and caches it.
     * @param proceedingID
     * @param forceReload true if reload should be forced
     */
    loadScheduleRelevantDataByID(proceedingID: string, forceReload = false) {
      const appStore = useAppStore();

      if (forceReload) {
        this.scheduleContainer[proceedingID] = {
          ...this.scheduleContainer[proceedingID],
          invalidated: true,
        };
      }

      if (
        typeof this.scheduleContainer[proceedingID] === "undefined" ||
        this.scheduleContainer[proceedingID].loading !== undefined ||
        this.scheduleContainer[proceedingID].invalidated
      ) {
        appStore.showPageLoadingIndicator({
          id: "loadScheduleRelevantData",
          text: "Einen Moment bitte, die Zeitplanung wird geladen.",
        });

        this.scheduleContainer[proceedingID] = {
          loading: true,
          invalidated: false,
          monitoringAktiv: false,
          termine: [],
        };
        return new Promise((resolve, reject) => {
          openAPIFactory
            .terminResourceApiFactory()
            .getZeitplanung(proceedingID)
            .then((response) => {
              this.setScheduleContainer({
                proceedingID,
                scheduleData: response.data,
              });
              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Die Ermittlung der Zeitplanung ist fehlgeschlagen!",
              });
              reject(error);
            })
            .finally(() => {
              appStore.hidePageLoadingIndicator("loadScheduleRelevantData");
            });
        });
      }
      return Promise.resolve();
    },
    /**
     * (De-)activates the monitoring of a specific proceeding.
     *
     * Requests to the K1 will only be made with valid arguments.
     * @param payload
     * @param payload.proceedingID The proceeding ID the monitoring state should get altered for.
     * @param payload.monitoringState True, if a schedule monitoring should be performed.
     * @param payload.nonMonitoringReason Object containing the non-monitoring reason. Must be set for deactivated monitorings.
     */
    setScheduleMonitoringStateByID(payload: {
      proceedingID?: string;
      monitoringState?: boolean;
      nonMonitoringReason?: CodeNichtMonitoringGrund;
    }): Promise<MonitoringStatusRest | false> {
      const planID = payload.proceedingID;

      if (
        planID !== undefined &&
        (payload.monitoringState || (!payload.monitoringState && payload.nonMonitoringReason))
      ) {
        const monitoringState: MonitoringStatusRest = {
          aktiv: payload.monitoringState,
        };

        if (!payload.monitoringState) {
          monitoringState["nichtMonitoringGrund"] = payload.nonMonitoringReason;
        }

        return new Promise((resolve, reject) => {
          openAPIFactory
            .monitoringResourceApiFactory()
            .setMonitoringState(monitoringState, planID)
            .then((response) => {
              resolve(response.data);

              if (payload?.monitoringState && payload.nonMonitoringReason) {
                this.setScheduleMonitoringStateInScheduleContainerByID({
                  planID,
                  monitoringState: payload?.monitoringState,
                  nonMonitoringReason: payload.nonMonitoringReason,
                });
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }

      return Promise.resolve(false);
    },
    /**
     * Sets the schedule data of a specific proceeding.
     * @param payload
     * @param payload.proceedingID The proceeding ID.
     * @param payload.scheduleData The schedule data as derived from the K1.
     */
    setScheduleContainer(payload: { proceedingID: string; scheduleData: ZeitplanungRest }) {
      this.scheduleContainer[payload.proceedingID] = {
        monitoringAktiv: payload.scheduleData.monitoringStatus?.aktiv || false,
        nichtMonitoringGrund: payload.scheduleData.monitoringStatus?.nichtMonitoringGrund,
        termine: payload.scheduleData.termine || [],
        invalidated: false,
        loading: undefined,
      };
    },
    /**
     * Sets the schedule data of a specific proceeding.
     * @param payload
     * @param payload.planID The proceeding ID that the monitoring state should be adjusted for
     * @param payload.monitoringState The activation state of the monitoring
     * @param payload.nonMonitoringReason The proceeding ID that the monitoring state should be adjusted for
     */
    setScheduleMonitoringStateInScheduleContainerByID(payload: {
      planID: string;
      monitoringState: boolean;
      nonMonitoringReason: CodeNichtMonitoringGrund;
    }) {
      this.scheduleContainer[payload.planID].monitoringAktiv = payload.monitoringState;
      this.scheduleContainer[payload.planID].nichtMonitoringGrund = payload.nonMonitoringReason;
    },
    /**
     * Update a proceeding appointment.
     * @param payload
     * @param payload.planID The proceeding ID this appointment data is for
     * @param payload.vtsID The ID of the VTS the appointment is located in
     * @param payload.vtsRound The number of the round of the VTS
     * @param payload.appointmentData The complete appointment data object
     * @param payload.invalidateSchedule True if the schedule page should get invalidated and a reload should be forced
     * @param payload.invalidateAppointments True if the appointment data should forcefully be reloaded
     */
    updateProceedingAppointment(payload: {
      planID: string;
      vtsID: string;
      vtsRound: number;
      appointmentData: TerminDetailRest;
      invalidateSchedule: boolean;
      invalidateAppointments: boolean;
    }) {
      const tasksStore = useTasksStore();
      const vtsRoundObj = tasksStore.vtsRoundData(payload.planID, payload.vtsID, payload.vtsRound);

      if (vtsRoundObj !== undefined) {
        const appointmentIndex =
          vtsRoundObj.termine?.findIndex(
            (appointment) => appointment.terminID === payload.appointmentData.terminID,
          ) || 0;

        if (!vtsRoundObj.termine) {
          vtsRoundObj.termine = [];
        }

        vtsRoundObj.termine[appointmentIndex] = payload.appointmentData;

        tasksStore.setVtsRound({
          planID: payload.planID,
          vtsID: payload.vtsID,
          roundID: payload.vtsRound,
          roundData: vtsRoundObj,
        });

        if (
          payload.invalidateSchedule &&
          typeof this.scheduleContainer[payload.planID] !== "undefined"
        ) {
          this.scheduleContainer[payload.planID].invalidated = true;
        }

        if (
          payload.invalidateAppointments &&
          this.appointmentContainer[payload.planID] !== undefined
        ) {
          this.loadProceedingAppointmentsByID({ proceedingID: payload.planID, forceReload: true });
        }
      }
    },
    /**
     * updates the appointment and returns the received scheduleData
     * @param planID
     * @param terminID
     * @param changedAppointmentData data of edited appointment
     * @param recalculateDates nachfolgendeNeuBerechnen
     * @param recalculatePlannedDates geplanteNeuBerechnen
     */
    updateAppointment(
      planID: string,
      terminID: string,
      changedAppointmentData: object,
      recalculateDates: boolean,
      recalculatePlannedDates: boolean,
    ) {
      return new Promise((resolve, reject) => {
        const zeitPlanungUpdateRest = { geaenderterTermin: changedAppointmentData };

        openAPIFactory
          .terminResourceApiFactory()
          .updateZeitplanungExisting(
            zeitPlanungUpdateRest,
            planID,
            terminID,
            recalculateDates,
            recalculatePlannedDates,
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /**
     * adds the appointment and returns the received scheduleData
     * @param planID
     * @param prevAppointmentID
     * @param newAppointmentData data of appointment to add
     * @param recalculateDates nachfolgendeNeuBerechnen
     * @param recalculatePlannedDates geplanteNeuBerechnen
     */
    addAppointment(
      planID: string,
      prevAppointmentID: string,
      newAppointmentData: object,
      recalculateDates: boolean,
      recalculatePlannedDates: boolean,
    ) {
      return new Promise((resolve, reject) => {
        let appointments = this.scheduleContainer[planID].termine;
        const prevAppointmentIndex = appointments.findIndex(
          (appointment) => appointment.terminID === prevAppointmentID,
        );

        appointments.splice(prevAppointmentIndex + 1, 0, newAppointmentData);
        const zeitPlanungEditRest = { termine: appointments };

        openAPIFactory
          .terminResourceApiFactory()
          .updateZeitplanungNew(
            zeitPlanungEditRest,
            planID,
            recalculateDates,
            recalculatePlannedDates,
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    /**
     * Returns all appointments of a given proceeding.
     */
    getAppointmentsByProceedingID(): (
      proceedingID: string,
      onlyStructuralSoundAppointments: boolean,
      isPV: boolean,
    ) => TerminDetailRest[] {
      const fundamentalStore = useFundamentalStore();

      return (
        proceedingID: string | undefined,
        onlyStructuralSoundAppointments = true,
        isPV = false,
      ) => {
        const selectedAppointmentContainer: AppointmentContainer = isPV
          ? this.pvAppointmentContainer
          : this.appointmentContainer;

        let isLoading = false;

        if (proceedingID) {
          let appointmentContainerItem = selectedAppointmentContainer[proceedingID];

          if (
            typeof appointmentContainerItem === "object" &&
            appointmentContainerItem !== null &&
            "loading" in appointmentContainerItem
          ) {
            isLoading = appointmentContainerItem.loading !== undefined;
          }
        }

        let appointments =
          proceedingID !== undefined &&
          typeof selectedAppointmentContainer[proceedingID] !== "undefined" &&
          !isLoading
            ? (selectedAppointmentContainer[proceedingID] as TerminDetailRest[])
            : [];
        const datumsStatus = fundamentalStore.datumsStatusByName;

        if (onlyStructuralSoundAppointments) {
          appointments = appointments.filter((appointment) => {
            if (appointment.datumsstatus) {
              switch (appointment.datumsstatus.code) {
                // stattgefunden
                case datumsStatus.stattgefunden:
                  return Boolean(appointment.stattgefundenerZeitraum);
                // prognostiziert
                case datumsStatus["initial prognostiziert"]:
                  return Boolean(appointment.initialPrognostizierterZeitraum);
                // berechnet
                case datumsStatus.berechnet:
                // geplant
                // eslint-disable-next-line no-fallthrough
                case datumsStatus.geplant:
                  return Boolean(appointment.geplanterOderBerechneterZeitraum);
                default:
                  return false;
              }
            }
            return false;
          });
        }

        return [...appointments];
      };
    },
    /**
     * Returns the schedule data object of a given proceeding ID.
     */
    getScheduleDataByProceedingID(): (proceedingID: string) => object | undefined {
      return (proceedingID) => {
        return proceedingID !== undefined &&
          typeof this.scheduleContainer[proceedingID] !== "undefined" &&
          this.scheduleContainer[proceedingID].loading === undefined
          ? this.scheduleContainer[proceedingID]
          : undefined;
      };
    },
    /**
     * Returns the state of monitoring (monitoringAktiv) of a given proceeding ID.
     */
    getMonitoringStateById(): (proceedingID: string) => boolean {
      return (proceedingID) => {
        return proceedingID !== undefined &&
          typeof this.scheduleContainer[proceedingID] !== "undefined" &&
          this.scheduleContainer[proceedingID].loading === undefined
          ? Boolean(this.scheduleContainer[proceedingID].monitoringAktiv)
          : false;
      };
    },
    /**
     * Returns the reason for monitoring deactivation for a given proceeding ID.
     */
    getMonitoringDeactivationReasonById(): (
      proceedingID: string,
    ) => CodeNichtMonitoringGrund | undefined {
      return (proceedingID) => {
        return proceedingID !== undefined &&
          typeof this.scheduleContainer[proceedingID] !== "undefined" &&
          this.scheduleContainer[proceedingID].loading === undefined
          ? this.scheduleContainer[proceedingID].nichtMonitoringGrund
          : undefined;
      };
    },
    /**
     * Returns the relevant appointments (termine) for a given proceeding ID.
     */
    getScheduleRelevantAppointmentsById(): (proceedingID: string) => TerminDetailRest[] {
      return (proceedingID) => {
        return proceedingID !== undefined &&
          typeof this.scheduleContainer[proceedingID] !== "undefined" &&
          this.scheduleContainer[proceedingID].loading === undefined
          ? this.scheduleContainer[proceedingID].termine
          : [];
      };
    },
  },
});
