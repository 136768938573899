import { version } from "/package.json";
import { useAppStore } from "@/stores/app.ts";

/**
 * Function to show app information as console info
 * @returns {void}
 */
export async function logAppInfos() {
  const appStore = useAppStore();

  // in case of error stores message in variable
  let errorMsg = "";

  try {
    await appStore.loadK1Version();
  } catch (e) {
    errorMsg = e;
  }

  // variables for the output
  const versionInfo = appStore.k1Version;
  // only available after the build process in azure devops
  const placeholderMessage = "Diese Information steht nicht zur Verfügung";

  // styles for the output
  const stylesHeadline = "font-size: 14px; font-weight: 700;";
  const stylesTitle =
    "color: #2b3980; font-size: 14px; font-weight: 700; text-decoration: underline;";
  const stylesLabel = "font-weight: 700;";
  const stylesText = "font-weight: 400;";
  const stylesError = "color: #dc3545; font-weight: 700;";

  // prevent output in vitest
  if (typeof process === "object") return;

  console.groupCollapsed("%cApplication information", stylesHeadline);
  // frontend
  console.info("%cFrontend", stylesTitle);
  console.info(`%cVersion:      %c${version}`, stylesLabel, stylesText);
  console.info(`${window.location.origin}/version.txt`);
  console.info(" ");

  // backend
  if (errorMsg) console.info(`%c${errorMsg}`, stylesError);
  console.info("%cBackend", stylesTitle);
  console.info(
    `%cVersion:      %c${versionInfo.version || placeholderMessage}`,
    stylesLabel,
    stylesText,
  );
  console.info(
    `%cBuild:        %c${versionInfo.build || placeholderMessage}`,
    stylesLabel,
    stylesText,
  );
  console.info(
    `%cZeitpunkt:    %c${versionInfo.timestamp || placeholderMessage}`,
    stylesLabel,
    stylesText,
  );
  console.info(
    `%cBranch:       %c${versionInfo.branch || placeholderMessage}`,
    stylesLabel,
    stylesText,
  );
  console.info(
    `%cRevision:     %c${versionInfo.revision || placeholderMessage}`,
    stylesLabel,
    stylesText,
  );
  console.groupEnd("Application information");
}

/**
 * Scroll to the top of the window
 * @param {string} behavior
 * @returns {void}
 */
export function scrollToTop(behavior = "instant") {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior,
  });
}

/**
 * Scroll to a specific element
 * @param {string} elementID
 * @param {string} [behavior]
 * @param {number} [offset]
 */
export function scrollToElement(elementID, behavior = "instant", offset = -100) {
  const element = document.getElementById(elementID);
  const y = element.getBoundingClientRect().top + window.pageYOffset + offset;

  window.scrollTo({ top: y, behavior: behavior });
}

/**
 * Return a default string containing the trace id
 * @param {Object} data - object containing the response or error data
 * @returns {string} string containing the markup and trace id or empty string
 */
export function getTraceIdString(data) {
  const traceId = data?.response?.headers?.traceid;

  return traceId ? `<br><br><b>Fehler ID</b>: ${traceId}` : "";
}

/**
 * Downloads blob data
 * @param {string} filename The name of the file that will be downloaded
 * @param {Blob} blobData Blob source
 * @param {Object} blobOptions Blob options
 */
export function downloadBlob(filename, blobData, blobOptions = {}) {
  const blob = new Blob([blobData], blobOptions);
  const href = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = href;
  link.setAttribute("download", filename);
  link.click();

  window.URL.revokeObjectURL(href);
}

/**
 * Returns a URL string without query string
 * @param {string} url - url containing the query string
 * @returns {string} url without query string
 */
export function removeUrlParameters(url) {
  if (!url) return "";

  let urlObj = new URL(url);

  urlObj.search = "";

  return urlObj.toString();
}

/**
 * removes focus from the currently focused element (e.g. in a modal) to prevent accessibility issues
 * @returns {void}
 */
export function removeFocus() {
  document.activeElement?.blur();
}
